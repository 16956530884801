<template>
    <T3ChoicesField
        v-model.number="$app"
        :label="label"
        :readonly="readonly"
        :expanded="expanded"
        choices="Apps"
    />
</template>

<script>
export default {
    name: 'AppChoices',
    props: {
        modelValue: {
            type: Number,
            default: null,
        },
        label: {
            type: String,
            default: 'App',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    computed: {
        $app: {
            get() {
                return this.modelValue
            },
            set(newval) {
                this.$emit('update:modelValue', newval)
            },
        },
    },
}
</script>
