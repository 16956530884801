<template>
    <div class="field">
        <label class="checkbox is-size-7 flex items-center justify-center">
            <input
                v-bind="$attrs"
                class="mr-1"
                type="checkbox"
                :checked="checked"
                :disabled="readonly"
                @input="onInput"
            />
            {{ label }}
        </label>
    </div>
</template>

<script>
import Field from './Field'

export default {
    name: 'T3CheckField',
    extends: Field,
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    computed: {
        checked() {
            if (this.modelValue) {
                return 'checked'
            }
            return undefined
        },
    },
    methods: {
        onInput(event) {
            this.$emit('update:modelValue', event.target.checked)
        },
    },
}
</script>
