<template>
    <T3Control
        :label="label"
        :right-aligned="rightAligned"
        :errors="errors"
    >
        <span
            class="is-size-7"
            :class="{
                'has-text-danger': errors.length,
            }"
        >
            <slot></slot>
        </span>
    </T3Control>
</template>

<script>
import Control from './Control'

export default {
    name: 'T3StaticField',
    extends: Control,
}
</script>
